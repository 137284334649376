.cookies-container{
    z-index: 1000;
    position: absolute;
    background-color: #3E2E7E;  
    bottom: 50px;
    height: 300px;
    z-index: 1000;
}
.cookies-header{
    color: white;
}
.cookies-text{
    font-size: small;
    color: white;

}