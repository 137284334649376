.layout-container {
	display: flex;
	flex-direction: column;
	margin: 0;	
}

.layout-top {
	float: 1;
	position: fixed;
	width: 100%;
	z-index: 1000;	
}

.layout-content {
	flex: 3;
	overflow-x: hidden;	
	margin-bottom: 70px;
}

.layout-bottom {
	flex: 1;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	height: 75px;
	z-index: 1000;
}
.layout-bottom-school{
	color: white;
	font-size: x-small;
	margin: 0;
	padding: 0;
}