.container{
    margin: 0px;
	padding: 0px;	    
}
.label{    
    color: white;
	font-size: small;
    font-weight: 600;
    margin: 0;
	padding: 0;
    cursor: pointer;
}