.topbar-container {	
	display: flex;
	flex-direction: row;
	justify-content: flex-start;	
	background: #3E2E7E;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
.topbar-nomenu{
	height: 0.2rem;
}

.topbar-container > a {
	position: relative;
	flex-grow: 1;
	opacity: .5;
	font-weight: 600;
	font-size: 12px;
	padding: 12px;	
	padding-bottom: 16px;
	padding-top: 16px;
	background-color: transparent;
	color: #fff;
}

.topbar-container > a:hover,
.topbar-container > a:focus {
	text-decoration: none;
}

.topbar-container > a.active {
	opacity: 1;
}

.topbar-container > a.active::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #fff;
	height: 4px;
}