.menu-item-container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-evenly;
	color: #bebebe;
	z-index: 12;
}

.menu-item-background {	
	border-radius: 100% 100% 0 0;
	/* to hide overlaping backgound for menu item */
	margin-top: -1px;	
	height: 60px;
}

.menu-item {
	fill: white;
	padding: 7px;	
	height: 100%;
}
.menu-item-icon svg{
	height: 25px;
	width: 20px;	
}
.menu-item-text{  	
	font-weight: bolder;
	font-size: 12px;
	line-height: 14px;
	text-align: center;	
	color: white;
}
.active {	
	border-radius: 20px;
	background-color: white;
}
