.progress-bar-container {
	position: absolute;
    top: 40%;
    left: 45%;
	z-index: 200;	
}

.progress-bar-spinner {
	height: '50px';
    width: '50px';
    
}
