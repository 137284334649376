.select-school {
	border-radius: 16px;
	margin: 10px;
}
.select-school-header {
	font-size: x-large;
	margin-top: 20px;
	font-weight: 200;
}
.select-school-buttons {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-size: x-large;
	margin-top: 20px;
	font-weight: 200;
}

.tile {
	height: 70px;
	width: 80%;
	margin: 10px;
}
