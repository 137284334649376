body {
	background-color: white;
	font-size: 16px;
}

.app-max-size {
	max-width: 511px;
	max-height: 836px;
}
.App {
	text-align: center;
	margin: auto;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}
.app-text-muted {
	line-height: 2;
	font-size: 12px;
	font-weight: 200;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.app-card {
	border-radius: 5px;
	/* box-shadow: 1px 1px 15px rgb(47, 60, 114); */
}

.app-bg {
	background: #f1f0f0;
}

.app-bg-color {
	background-color: #3e2e7e;
}

.app-bg-color2 {
	background-color: #1c1e27;
}
.app-text-main{
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;
	text-align: center;
}
.app-text-title {
	color: white;
	font-size: large;
	text-align: center;
	font-weight: bold;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 0.5rem;
	border-radius: 10px;
	background-color: #3e2e7e;
}

.app-text-label {
	color: black;
	font-size: medium;
	text-align: start;
	font-weight: bold;
	margin: 0rem 0rem 0rem 1rem;
}

.app-text-label-stamp{
	font-size: x-large;
	color: gray;
	align-self: center;
	justify-self: center;
	text-align: center;
	border: 1px solid;
	border-radius: 20px;
	box-shadow: initial ;
	padding: 15px;    
	margin-top: 50px;
	margin-left: 20px;
	margin-right: 20px;
	margin-bottom: 20px;

	-webkit-box-shadow: 5px 5px 15px 0px #3E2E7E;
	-moz-box-shadow: 5px 5px 15px 0px #3E2E7E;
	box-shadow: 5px 5px 10px 0px #3E2E7E;

}

.app-text-title-sm {
	color: black;
	font-size: xx-small;
	text-align: start;		
}

.app-text-data {
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	margin-right: 30px;
	text-align: right;
	color: #3e2e7e;
}

.app-text-lg {
	font-style: normal;
	font-weight: bold;
	font-size: 26px;
	margin-right: 30px;
	text-align: center;
	color: #3e2e7e;
}

.app-text-sm {
	font-style: normal;	
	font-size: 16px;	
	text-align: center;
	color: #3e2e7e;
}

button.confirm {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;
	box-shadow: none;
	text-align: center;
	border: none;
	color: #ffffff;
	background: #3e2e7e;
	height: 40px;
	width: 120px;
}

button.main {
	line-height: 23px;
	color: #ffffff;
	background: #504480;
	border-radius: 16px;
	box-shadow: 0px 2px 10px #50448004;
	border-color: #504480;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;
	text-align: center;
	border: none;
	

}

button.cancel {
	color: #3e2e7e;
	background: #ffffff;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;
	box-shadow: none;
	text-align: center;
	border: none;
	height: 40px;
	width: 120px;
}

button.submit {
	border-radius: 16px;
	background-color: #3e2e7e;
	border-color: #3e2e7e;
}

button.danger {
	border-radius: 16px;
	background-color: #f00000;
	border-color: #f00000;
	color: #ffffff;
}

button:disabled {
	border-radius: 16px;
	background-color: white;
	border-color: #3e2e7e;
	border: 1px solid;
	color: #a9a9a9;
}

button.box {
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 19px;
	box-shadow: 0px 2px 10px rgba(223, 214, 255);
	text-align: center;
	border: 0px solid;
	color: black;
	background: white;
	height: 60px;
	width: 90%;
}

button.options {
	height: 50px;
}

.app-boxes-shadow {
	-webkit-box-shadow: 5px 5px 15px 0px #3e2e7e;
	-moz-box-shadow: 5px 5px 15px 0px #3e2e7e;
	box-shadow: 5px 5px 10px 0px #3e2e7e;
}
.app-border-bottom-light {
	border-bottom: 1px solid #0505050c;
}
.app-text-title-sub {
	font-size: xx-small;
	font-weight: 100;
	margin-top: 0;
}

img.rounded-corners {
	border-radius: 10px;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}