.navigation-container{  
  /* box-shadow: -1px -1px 5px black; */
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;  
  align-items: top;  
  font-size: 10px;  
  background-color: transparent;   
  margin: auto;
}
.navigation-side {        
  flex-grow: 1;
  /* to hide overlaping backgound for menu item */
  margin-top: -1px;
}

.border-curved-left{
  border-radius: 20px 0px 0 0;
}
.border-curved-right{
  border-radius: 0px 20px 0 0;
}

.border-curved-lr{
  border-radius: 20px 20px 0 0;
}

.navigation-container svg{
  width: 20px;
  height: 20px;
  color: rgb(173, 173, 173);  
}
.navigation-container-footer {
  position: absolute;
  background-color: #3E2E7E;  
  bottom: 0;
  height: 30px;
  width: 100%;
  z-index: -1;
}
