/* ThisIsTheEndPage.css */

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px; /* Add margin on top */
    flex-direction: column;
}

/* Style image */
img {
    display: block;
    margin: 0 auto;
}

.texts {
    text-align: center;
}

.main-text {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.text-second {
    font-size: 18px;
    margin-bottom: 5px;
}

.text-third {
    font-size: 14px;
    color: gray;
}